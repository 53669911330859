import React, { Component } from "react";

export default class Contact extends Component {
	constructor(props){
		super(props);
		this.state = {
			ft_checked: false,
			pt_checked: false,
			pilates_checked: false,
			kettlebell_checked: false,
			yoga_checked: false,
			mobility_checked: false
		}
		this.handleFTCheckboxClick = this.handleFTCheckboxClick.bind(this);
		this.handleYogaCheckboxClick = this.handleYogaCheckboxClick.bind(this);
		this.handleMobilityCheckboxClick = this.handleMobilityCheckboxClick.bind(this);
		this.handleKettlebellCheckboxClick = this.handleKettlebellCheckboxClick.bind(this);
		this.handlePTCheckboxClick = this.handlePTCheckboxClick.bind(this);
		this.handlePilatesCheckboxClick = this.handlePilatesCheckboxClick.bind(this);
	}

	handleFTCheckboxClick() {
		this.setState({
			ft_checked: !this.state.ft_checked
		})
	}

	handleYogaCheckboxClick(){
		this.setState({
			yoga_checked: !this.state.yoga_checked
		})
	}

	handleMobilityCheckboxClick(){
		this.setState({
			mobility_checked: !this.state.mobility_checked
		})
	}

	handleKettlebellCheckboxClick(){
		this.setState({
			kettlebell_checked: !this.state.kettlebell_checked
		})
	}

	handlePTCheckboxClick(){
		this.setState({
			pt_checked: !this.state.pt_checked
		})
	}

	handlePilatesCheckboxClick(){
		this.setState({
			pilates_checked: !this.state.pilates_checked
		})
	}

	render() {
		return (
			<div>
				<section id="contact-us" class="wrapper style2">
					<div class="inner narrow">
						<header class="heading major">
							<h2>Our team is here to help</h2>
							<p>Do you have any questions or want to know more about our services? Drop us a message.</p>
						</header>
						<form class="gform" 
							action="https://script.google.com/macros/s/AKfycbx5iLwrKO9IFKKH2880rquPBhxpSQds8pYMDa_aeA/exec"
							method="post" data-email="theblackboxconcept@gmail.com">
							<div class="field half">
								<input name="Name" id="name" placeholder="Your Name" type="text" required />
							</div>
							<div class="field half">
								<input name="Email" id="email" placeholder="Your Email Address" type="email" required />
							</div>
							<div class="field">
							{/* <label style={{color: "rgba(255, 255, 255, 0.5)"}}>Choose your sport :</label> */}
								<div class="row">
									<div class="col-4 col-12-small">
										<input type="checkbox" id="checkbox_functional_training" name="Functional Training" onChange={this.handleFTCheckboxClick} value={this.state.ft_checked ? "Sign me up!" : "No thanks"} />
										<label for="checkbox_functional_training">Functional Training</label>
									</div>
									<div class="col-4 col-12-small">
										<input type="checkbox" id="checkbox_yoga" name="Yoga" onChange={this.handleYogaCheckboxClick} value={this.state.yoga_checked ? "Sign me up!" : "No thanks"}/>
										<label for="checkbox_yoga">Yoga</label>
									</div>
									<div class="col-4 col-12-small">
										<input type="checkbox" id="checkbox_mobility" name="Mobility" onChange={this.handleMobilityCheckboxClick} value={this.state.mobility_checked ? "Sign me up!" : "No thanks"}/>
										<label for="checkbox_mobility">Mobility</label>
									</div>
									<div class="col-4 col-12-small">
										<input type="checkbox" id="checkbox_kettlebell" name="Kettlebell" onChange={this.handleKettlebellCheckboxClick} value={this.state.kettlebell_checked ? "Sign me up!" : "No thanks"}/>
										<label for="checkbox_kettlebell">Kettlebell</label>
									</div>
									<div class="col-4 col-12-small">
										<input type="checkbox" id="checkbox_personal_training" name="Personal Training" onChange={this.handlePTCheckboxClick} value={this.state.pt_checked ? "Sign me up!" : "No thanks"}/>
										<label for="checkbox_personal_training">Personal Training</label>
									</div>
									<div class="col-4 col-12-small">
										<input type="checkbox" id="checkbox_intensity" name="Intensity" onChange={this.handlePilatesCheckboxClick} value={this.state.pilates_checked ? "Sign me up!" : "No thanks"}/>
										<label for="checkbox_intensity">Intensity</label>
									</div>
								</div>
							</div>
							<div class="field">
								<textarea name="Message" id="message" rows="6" placeholder="Your Message" required></textarea>
							</div>
							<div class="field">
								<ul class="actions special">
									<li><button class="button primary" type="submit">Send Message</button></li>
									<li><button class="button medium" type="reset">Clear Form</button></li>
								</ul>
							</div>
						</form>
					</div>
				</section>
			</div>
		)
	}
}