import React, { Component } from "react";

export default class BookYourSport extends Component {
	render() {
		return (
			<div>
				<section id="book-your-sport" class="wrapper" style={{ padding: "1rem" }}>
					<div class="inner">
						<div>
							<header class="heading major">
								<h2>Book your spot</h2>
								<p>The access to our facilities is only possible via the booking system. 
									In order to offer you the best quality of coaching and the most comfortable environment, 
									booking your spot is mandatory and  must be done at least 2 hours before the selected time slot.</p>
								
								<h4>Opening hours: <br/> 
								 Monday - Friday 07:00 - 20:00 <br/>
								 Saturday 09:30 - 13:30 <br/>
								 Regarding Covid 19 measures: we comply with  the latest health and safety regulations for the region of Brussels. </h4>

								
							</header>
							<iframe src="https://www.eversports.be/widget/w/sO0w0c"
								height="100%"
								width="100%"
								className="iframe"
								title="Calendar"></iframe>
						</div>
					</div>
				</section>
			</div>
		)
	}
}