import React, { Component } from "react";

export default class Team extends Component {
    render() {
        return (
            <section id="our-team" class="wrapper">
                <div class="inner">
                    <header class="heading major">
                        <h2>Get to know our team </h2>
                        <p>A team of professionals, from around the world,  exceling in their field, passionate about exercise and healthy lifestyle. 
                            {/* <a style={{textDecoration: "underline", color: "rgba(255, 255, 255, 0.5)"}} href="/partners" target="_blank" > */}
                            &nbsp;Our team grows everyday.
                            {/* </a> */}
                        </p>
                    </header>
                    <div class="profiles">
                        <div class="profile">
                            <div class="image">
                                <img src="images/Chris.jpg" alt="" />
                            </div>
                            <header class="heading">
                                <h3>Christos Sinapis</h3>
                                <p>Co-Founder - Coach</p>
                            </header>
                            <p>Specialist in mobility and rehabilitation. Burpees and planks are my favourite exercises. 
                                Workouts for all, no matter gender or age. Trainer for the greek armed forces, 8 years at the NATO sport 
                                centre both as trainer and assistant manager.</p>
                            <ul class="icons">
                                <li><a href="https://www.instagram.com/chris_sinapis/" class="icon brands fa-instagram" target="_blank"><span class="label">Instagram</span></a></li>
                            </ul>
                        </div>
                        <div class="profile">
                            <div class="image">
                                <img src="images/Kon.jpg" alt="" />
                            </div>
                            <header class="heading">
                                <h3>Konstantinos Sinapis</h3>
                                <p>Co-Founder - Head Coach</p>
                            </header>
                            <p>Passionated, motivated and supportive. Will push you to your limits and make you reach your goals.<br/><br/><br/> </p>
                            <ul class="icons">
                                <li><a href="https://www.instagram.com/kwsti/" class="icon brands fa-instagram" target="_blank"><span class="label">Instagram</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}