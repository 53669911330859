import React, { Component } from "react";
import { Link } from 'react-router-dom';

export default class Services extends Component {
	render() {
		return (
			<div>
				<section id="our-services" class="wrapper">
					<div class="inner">
						<header class="heading major">
							<h2>What’s your excuse?</h2>
							<p>Check out the variety of classes we offer and choose what’s best for you.</p>
						</header>
						 <div class="spotlights">
							<div class="spotlight">
								<article>
									
									<div class="content">
									<a href="#" class="image fit"><img src="images/01_ft.jpg" alt="" /></a>
										<p>This is what our bodies are meant to do. Perform your everyday activities better.</p>
									{/*<ul class="actions special">
											<li><Link to="/service/#functional-training" 
											class="button primary" target="_blank">Functional Training</Link>
											</li>
							    	</ul>*/}
									<h3>Functional Training</h3>
									</div>
								</article>
							</div>
							<div class="spotlight">
								<article>
									
									<div class="content">
									<a href="#" class="image fit"><img src="images/02_yogaa.jpg" alt="" /></a>
										<p>Relax your mind and body while increasing your flexibility and strength.</p>
										{/*<ul class="actions special">
											<li><Link to="/service/#yoga" class="button primary" target="_blank">Yoga</Link></li>
								</ul> */}
								<h3>Yoga</h3>
									</div>
								</article>
							</div>
							<div class="spotlight">
								<article>
									<div class="content">
									<a href="#" class="image fit"><img src="images/03_mob.jpg" alt="" /></a>
										<p>Indulge in a training that will take your muscles on a ride.</p>

										{/*<ul class="actions special">
											<li><a href="/service/#mobility" class="button primary" target="_blank">Mobility</a></li>
							</ul>*/}
							<h3>Mobility</h3>
									</div>
								</article>
							</div>
							<div class="spotlight">
								<article>
							
									<div class="content">
									<a href="#" class="image fit"><img src="images/04_ktb.jpg" alt="" /></a>
										<p>Be part of a highly effective training for improving total-body strength.</p>
										{/*<ul class="actions special">
											<li><Link to="/service/#kettlebell" class="button primary" target="_blank">Kettlebell</Link></li>
						</ul> */}
						<h3>Kettlebell</h3>
									</div>
								</article>
							</div>
							<div class="spotlight">
								<article>
								
									<div class="content">
									<a href="#" class="image fit"><img src="images/05_pt.jpg" alt="" /></a>
										<p>Set and achieve your personal goals with the best fitness experts.</p>
										{/*<ul class="actions special">
											<li><Link to="/service/#personal-training" class="button primary" target="_blank">Personal Training</Link></li>
					</ul>*/}
					<h3>Personal Training</h3>
									</div>
								</article>
							</div>
							<div class="spotlight">
								<article>
								
									<div class="content">
									<a href="#" class="image fit"><img src="images/06_int.jpg" alt="" /></a>
										<p>One hour workout, combining indoor cycling, weights or bodyweight movements.</p>
										{/*<ul class="actions special">
											<li><a href="/service/#pilates" class="button primary" target="_blank">Intensity</a></li>
				</ul>*/}
				<h3>Intensity</h3>
									</div>
								</article>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}