import React, { Component } from "react";
import {Link} from 'react-router-dom';

export default class Partners extends Component {

    componentDidMount = () => window.scrollTo(0, 0);

    componentDidUpdate = prevProps => {
        if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
    };

    render() {
        return (
            <div>
                <div id="partners" style={{backgroundColor: "black"}}>
                    <h1>OUR TEAM MEMBERS</h1>
                    <div class="partner_row">
                        <div class="box">
                            <div className="partner_content">
                                <span className="image partner"><img src="images/nicholas.jpg" alt="" /></span>
                                <div>
                                    <h3>Nicolas Genotte</h3>
                                    <p>Nicolas Genotte was born on November 09, 1993 in Marche-en-Famenne in Belgium. From an early age,
                                    he has been involved in different sports (judo, water polo, running, basketball etc.).
                                    It wasn't until the age of 21 that Nicolas began to take an interest in the kettlebell and building strength
                                    and different workout methodologies when he started CrossFit. 3 years later he decided to specialize in the
                                    use of the kettlebell and achieve his first international competition as an amateur. A few months later,
                                    Nicolas Genotte became the first Belgian in history to become professional in an IKMF federation and to qualify
                                    for the world championships. In November 2018 in Spain, Nicolas Genotte finished double Bronze medalist in Elite
                                    division in Light Weight category. A big first for a young 24 year old athlete who had only started competing 9
                                    months earlier. A very promising start to his career which led to a title of European vice-champion in April 2019
                                    and a presentation of the honorary Master Of Sport title by the president of the federation. In November 2019,
                                    during the IKMF Kettlebell Sport World Championships in Poland, Nicolas Genotte was crowned world champion in the
                                    -75kg category. Nicolas Genotte is also passionate about other sports targeting strength development, but not only!
                                    It is open to several sports disciplines and above all wants to make kettlebell sport more widely known in Belgium. He
                                    has also been the founder of MindFit BXL since June 2017 as a specialized Head Coach and carries out workshops and group
                                    lessons in different rooms in Brussels and Wallonia.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="partner_row">
                        <div class="box">
                            <div className="partner_content">
                                <span className="image partner"><img src="images/Ghassen.jpg" alt="" /></span>
                                <div>
                                    <h3>Ghassen Efta</h3>
                                    <p>I am a Greek-Tunisian based in Brussels. I work in coaching, communication and management for one of the most important
                                    Kickboxing-MMA team in Europe. For years now I have travelled the world for this sport with our team and our fighters
                                    to take part in the biggest fighting events. Kickboxing is for me more than a sport, it’s my life and my daily drug!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
				<section id="footer">
					<div class="inner">
						<ul class="icons">
							<li><a href="https://maps.google.com/?q=Avenue des Celtes 28, Etterbeek" class="icon solid fa-map-marker-alt" target="_blank"><span class="label">Location</span></a></li>
						</ul>
					</div>
					<div class="copyright">
						<ul>
							<li>&copy; Blackbox Concept. All rights reserved.</li>
							<li><Link to="/imprint">Imprint</Link></li>
							<li><Link to="/privacy-policy">Privacy Policy</Link></li>
						</ul>
				</div>
				</section>
			</div>
            </div>
        )
    }
}