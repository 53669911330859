import React, { Component } from "react";

export default class PersonalTraining extends Component {

	componentDidMount = () => window.scrollTo(0, 0);

	componentDidUpdate = prevProps => {
		if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
	};

	render() {
		return (
			<div class="service_bg">
				<div id="personal-training">
					{/* <h2>PERSONAL TRAINING</h2> */}
					<div class="row">
						<div class="col-6 col-12-medium">
							<div>
								<div class="row gtr-50 gtr-uniform">
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
								</div>
							</div>
						</div>
						<div class="col-6 col-12-medium">
							<div class="blockquote_div_container">
								<h2>PERSONAL TRAINING</h2>
								<blockquote>
									You deciding to get fitter and healthier is the best choice to ever make.
									We are here to make that choice even easier by taking a professional approach to realistic goal planning.
									Our wide range of fitness experts will listen to your needs and work on providing you with effective programming
									that best fits your needs. You will be constantly supported and motivated to work hard and give the best you got!
						In case you have a question please contact us and we will direct you to the right fitness expert!</blockquote>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}