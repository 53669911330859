import React, { Component } from "react";
import Footer from "../Footer";
import {Link} from 'react-router-dom';


export default class PrivacyPolicy extends Component {

  componentDidMount = () => window.scrollTo(0, 0);

  componentDidUpdate = prevProps => {
    if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
  };


  render() {
    return (
      <div style={{backgroundColor: "black"}}>
        <div id="privacy-policy">
          <header>
            <h2 id="privacy_policy_text">Privacy Policy</h2>
            <p>
              This page is used to inform www.blackbox-concept.com visitors
              regarding our policies with the collection, use, and disclosure of
              Personal Information if anyone decided to use our services listed at
              the www.blackbox-concept.com website. If you choose to use our
              website, then you agree to the collection and use of information in
              relation with this policy. The Personal Information that we collect
              are used for providing our services. We will not use or share your
              information with anyone except as described in this Privacy Policy.
          </p>
          </header>
          <h3>Information Collection and Use</h3>
          <p>
            For a better experience while using our website, we may require you to
            provide us with certain personally identifiable information, including
            but not limited to your name, phone number, and postal address. The
            information that we collect will be used to contact or identify you.
        </p>
          <h3>Log Data</h3>
          <p>
            We want to inform you that whenever you visit our website, we collect
            information that your browser sends to us that is called Log Data.
            This Log Data may include information such as your computer's Internet
            Protocol (“IP”) address, browser version, pages of our Service that
            you visit, the time and date of your visit, the time spent on those
            pages, and other statistics.
        </p>
          <h3>Cookies</h3>
          <p>
            Cookies are files with small amount of data that is commonly used as
            anonymous unique identifier. These are sent to your browser from the
            website that you visit and are stored on your computer's hard drive.
            Our website uses these “cookies” to collect information. You have the
            option to either accept or refuse these cookies, and know when a
            cookie is being sent to your computer. If you choose to refuse our
            cookies, you may not be able to use some portions of our Service.
        </p>
          <h3>Security</h3>
          <p>
            We value your trust in providing us your Personal Information, thus we
            are striving to use commercially acceptable means of protecting it.
            But remember that no method of transmission over the internet, or
            method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
        </p>
          <h3>Links to Other Sites</h3>
          <p>
            Our Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no control
            over, and assume no responsibility for the content, privacy policies,
            or practices of any third-party sites or services.
        </p>
          <h3>Children's Privacy: </h3>
          <p>
            {" "}
          Our Services do not address anyone under the age of 13. We do not
          knowingly collect personal identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </p>
          <h3>Your rights as an European resident</h3>
          <p>
            If you are a European resident, you have the right to access personal
            information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact information
            below. Additionally, if you are a European resident we note that we
            are processing your information in order to fulfill contracts we might
            have with you (for example if you create an a Account and/or make an
            order through the Site), or otherwise to pursue our legitimate
            business interests listed above. Additionally, please note that your
            information will be transferred outside of Europe, including to Canada
            and the United States.
        </p>
          <h3>Social Plugins</h3>
          <p>
            No social plugins are used on our pages. The icons that you can find
            and use at the bottom of our website are simply links to our Twitter,
            Facebook, LinkedIn and Instagram pages. In this regard, we would like
            to point out that these social pages use cookies on the end devices of
            page visitors for the purpose of collecting usage information. These
            would theoretically enable us to provide the social page operators
            with anonymized statistical evaluations according to their own
            parameterization. However, we do not use these. We cannot say or
            control what these social accounts do with your user data. For the
            purpose and scope of data collection and the further processing and
            use of data by Twitter, Facebook, LinkedIn and Instagram as well as
            your rights and settings options to protect your privacy, please refer
            to the privacy policy of these social networks.
        </p>
          <h3>Hosting and Content Delivery Networks (CDN)</h3>
          <p>
            This website is hosted by an external service provider (Hostinger).
            The personal data collected on this website is stored on Hostinger's
            servers. This may include, but is not limited to, IP addresses,
            contact requests, meta and communication data, contract data, contact
            details, names, web page accesses and other data generated by a
            website. The use of Hostinger is for the purpose of fulfilling the
            contract with our potential and existing customers (Art. 6 para. 1
            lit. b DSGVO) and in the interest of a secure, fast and efficient
            provision of our online offer by a professional provider (Art. 6 para.
            1 lit. f DSGVO). Hostinger will only process your data to the extent
            necessary to fulfill its performance obligations and will follow our
            instructions with regard to this data.
        </p>
          <h3>Contact Us</h3>
          <p>
            If you have any questions or suggestions about our Privacy Policy, do
            not hesitate to contact us.
        </p>
        </div>
        {/* <Footer /> */}
        <div>
				<section id="footer">
					<div class="inner">
						<ul class="icons">
							<li><a href="https://maps.google.com/?q=Avenue des Celtes 28, Etterbeek" class="icon solid fa-map-marker-alt" target="_blank"><span class="label">Location</span></a></li>
							<li><a href="/#contact-us" class="icon solid fa-envelope"><span class="label">Email</span></a></li>
							<li><a href="https://www.instagram.com/black_box_concept/" class="icon brands fa-instagram" target="_blank"><span class="label">Instagram</span></a></li>
						</ul>
					</div>
					<div class="copyright">
						<ul>
							<li>&copy; Blackbox Concept. All rights reserved.  0032 472 67 37 67 | info@blackbox-concept.com </li>
							<li><Link to="/imprint">Imprint</Link></li>
							<li><Link to="/privacy-policy">Privacy Policy</Link></li>
						</ul>
				</div>
				</section>
			</div>
      </div>
    );
  }
}
