import React, { Component } from "react";
import LineSeparator from '../Utilities/LineSeparator';
import FunctionalTraining from '../Classes_Offered/FunctionalTraining';
import Kettlebell from '../Classes_Offered/Kettlebell';
import PersonalTraining from '../Classes_Offered/PersonalTraining';
import Pilates from "./Pilates";
import Mobility from "./Mobility";
import Yoga from './Yoga';

export default class Service extends Component {

	componentDidMount = () => window.scrollTo(0, 0);

	componentDidUpdate = prevProps => {
		if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
	};

	render() {
		return (
			<div>
				<FunctionalTraining />
				<LineSeparator color="#000" />
				<Yoga />
				<LineSeparator color="#000" />
				<Mobility />
				<LineSeparator color="#000" />
				<Kettlebell />
				<LineSeparator color="#000" />
				<PersonalTraining />
				<LineSeparator color="#000" />
				<Pilates />
				<hr
					style={{
						backgroundColor: "black",
						height: 50
					}}
				/>
				<section id="footer">
					<div class="inner">
						<ul class="icons">
							<li><a href="https://www.facebook.com/YOGACATHEDRALE" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a></li>
							<li><a href="https://www.instagram.com/yoga_cathedrale/" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>
						</ul>
					</div>
					<div class="copyright">
						&copy; Blackbox Concept. All rights reserved.
				</div>
				</section>
			</div>
		)
	}
}