import React, { Component } from "react";

export default class Schedule extends Component {
  render() {
    return (
      <div>
        <section id="pricing-membership" class="wrapper">
          <div class="inner">
            <div>
              <header class="heading major">
                <h2>Pricing and Membership</h2>
                <h3>Early memberships can be frozen once a year without any charge. This offer is valid only for yearly memberships. </h3>
                <p></p>
              </header>
            </div>
          </div>
        </section>
        <div class="table-container">
          <span className="image fit img_pricing">
            <img src="images/prices_november.jpg" alt="" />
          </span>
        </div>
      </div>
    );
  }
}
