import React, { Component } from "react";

export default class Kettlebell extends Component {

	componentDidMount = () => window.scrollTo(0, 0);

	componentDidUpdate = prevProps => {
		if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
	};

	render() {
		return (
			<div class="service_bg">
				<div id="kettlebell">
					{/* <h2>KETTLEBELL</h2> */}
					<div class="row">
						<div class="col-6 col-12-medium">
							<div class="blockquote_div_container">
								<h2>KETTLEBELL</h2>
								<blockquote>
									The Kettlebell class is just a workout with Kettlebells and body weight exercises.
									The students in a kettlebell class will learn from fast and dynamic to slow grind movements.
									Fast and dynamic kettlebell movements are meant to build explosive power, increase joint mobility,
									and keep an elevated heart rate. Slow grind movements on the other hand are meant to increase total body
									strength, muscular strength and stamina. These exercises will help with overall body posture and positioning.</blockquote>
							</div>
							
						</div>
						<div class="col-6 col-12-medium">
							<div>
								<div class="row gtr-50 gtr-uniform">
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}