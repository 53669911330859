import React, { Component } from "react";

export default class AboutUs extends Component {
	render() {
		return (
			<div>
				<section id="about-us" class="wrapper">
					<div class="inner">
						<div>
							<header class="heading major">
								<h2>Why Us?</h2>
								<p>You deserve the best! An efficient  workout,  in a safe and fun way. Live longer! Live better!</p>
							</header>
							<div class="about-us-container">
							<p class="about-us">
								To be effective, a workout needs to be given by people who have the knowledge, experience and 
								time to prepare a program adapted to your individual needs. <br/>
								A sports professional takes into account many different parameters, in order to unleash your potential 
								and improve your body and mind.<br/>
								<br/>
								<div style={{textAlign: "left"}}>
								<p style={{textAlign: "center"}}>You are:</p>
								<ul>
									<li>A working woman/man</li>
									<li>Teenagers</li>
									<li>New mothers</li>
									<li>Those about to retire/retirees</li>
									<li>Students</li>
									<li>Newcomers in town</li>
									<li>Those totally new to the world of fitness </li>
									<li>Those recovering from injury</li>
									<li>People in search of better mobility</li>
								</ul>
								</div>
								<h4 style={{color: "#ffffff", font:"inherit", fontSize: "smaller"}}>Don’t leave your wellbeing to luck! Care for your body because it is a top priority!</h4>
							</p>			
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}