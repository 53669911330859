import React, { Component } from "react";

export default class FunctionalTraining extends Component {

	componentDidMount = () => window.scrollTo(0, 0);

	componentDidUpdate = prevProps => {
		if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
	};

	render() {
		return (
			<div class="service_bg">
				<div id="functional-training">
					{/* <h2>FUNCTIONAL TRAINING</h2> */}
					<div class="row functional">
						<div class="col-6 col-12-medium">
							<div>
								<div class="row gtr-50 gtr-uniform">
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
								</div>
							</div>
						</div>
						<div class="col-6 col-12-medium">
							<div class="blockquote_div_container">
								<h2>FUNCTIONAL TRAINING</h2>
								<blockquote>
									Functional training is a strength and conditioning training based on constantly varied functional exercises
									performed at high intensity. As we focus on technique, you will develop all 10 physical skills of fitness:
									Cardiovascular/respiratory endurance, Stamina, Strength, Flexibility, Power, Speed, Coordination, Agility, Balance
									and Accuracy.</blockquote>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}