import React, { Component } from "react";

export default class Yoga extends Component {

	componentDidMount = () => window.scrollTo(0, 0);

	componentDidUpdate = prevProps => {
		if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
	};

	render() {
		return (
			<div class="service_bg">
				<div id="yoga">
					{/* <h2>YOGA</h2> */}
					<div class="row">
						<div class="col-6 col-12-medium">
							<div class="blockquote_div_container">
								<h2>YOGA</h2>
								<blockquote>
									We welcome you to our new charming yoga studio in the heart of Brussels.
									Here you will find the ideal place to use the transformative powers of yoga to live a healthier and better life.
									Relax and challenge yourself at the same time by bringing together your body, mind and breath.
									Our teachers bring their unique experience and knowledge in a variety of yoga styles:
									Ashtanga, Iyengar and Vinyasa Flow. We encourage you to combine different classes to get the best results.
									Our doors are open to all of you: beginners, experienced yogis and newcomers.</blockquote>
							</div>
						</div>
						<div class="col-6 col-12-medium">
							<div>
								<div class="row gtr-50 gtr-uniform">
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}