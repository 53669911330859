import React, { Component } from "react";
import Footer from "../Footer";
import {Link} from 'react-router-dom';

export default class Imprint extends Component {
  componentDidMount = () => window.scrollTo(0, 0);

	componentDidUpdate = prevProps => {
		if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
	};

  render() {
    return (
      <div style={{backgroundColor: "black"}}>
        <div id="imprint">
          <header>
            <h2 id="imprint_text">IMPRINT</h2>
          </header>
          <h3>Information in accordance with Section § 5 TMG:</h3>
          <p> Enterprise Name: Three Mousquetaires</p>
          <p>
            Ownership: Christos Sinapis, Konstantinos
            Sinapis
        </p>
          <p>Enterprise number: 0743 372 069</p>
          <p>VAT: BE0743 372 069</p>
          <p>Address: Avenue des Celtes 28, Etterbeek, Brussels, Belgium</p>
          <p>Phone number: +32 484 61 56 86</p>
          <h3>Disclaimer</h3>
          <h4>Accountability for content</h4>
          <p>
            The contents of our pages have been created with the utmost care.
            However, we cannot guarantee the contents’ accuracy, completeness or
            topicality. According to statutory provisions, we are furthermore
            responsible for our own content on these web pages. In this matter,
            please note that we are not obliged to monitor the transmitted or
            saved information of third parties, or investigate circumstances
            pointing to illegal activity. Our obligations to remove or block the
            use of information under generally applicable laws remain unaffected
            by this as per §§ 8 to 10 of the Telemedia Act (TMG).
        </p>
          <h4>Accountability for links</h4>
          <p>
            Responsibility for the content of external links (to web pages of
            third parties) lies solely with the operators of the linked pages. No
            violations were evident to us at the time of linking. Should any legal
            infringement become known to us, we will remove the respective link
            immediately.
        </p>
          <h4>Privacy</h4>
          <p>
            The use of our website is usually possible without providing personal
            data. As far as personal data (e.g. name, address or e-mail addresses)
            is collected on our website, this is always done on a voluntary basis,
            as far as possible. This data will not be passed on to third parties
            without your express consent. We would like to point out that data
            transmission over the Internet (e.g. communication by e-mail) can have
            security gaps. A complete protection of data against access by third
            parties is not possible. The use by third parties of contact data
            published within the framework of the imprint obligation for the
            purpose of sending advertising and information material not expressly
            requested is hereby expressly prohibited. The operators of the site
            expressly reserve the right to take legal action in the event that
            unsolicited advertising information is sent, for example by spam mail.
        </p>
          <h4>Copyright</h4>
          <p>
            All texts, graphics, animations, videos and other contents of this
            website as well as their structure and arrangement are protected by
            copyright. Any kind of duplication, processing, distribution, storage
            and any kind of exploitation of the aforementioned contents in other
            electronic or printed publications requires the prior consent of the
            Three Mousquetaires. Unauthorized copying or storage of the
            information provided on this internet page is not permitted and is
            liable to prosecution. Insofar as content on these pages was not
            created by us, the copyrights of third parties must be observed.
        </p>
          <h4>SSL/TLS Encryption</h4>
          <p>
            This site uses SSL or TLS encryption for security reasons and to
            protect the transmission of confidential content, such as orders or
            inquiries that you send to us as site operator. You can recognize an
            encrypted connection by the fact that the address line of your browser
            changes from "http://" to "https://" and by the lock symbol in your
            browser line. If the SSL or TLS encryption is activated, the data you
            transmit to us cannot be read by third parties.
        </p>
        </div>
        {/* <Footer /> */}
        <div>
				<section id="footer">
					<div class="inner">
						<ul class="icons">
							<li><a href="https://maps.google.com/?q=Avenue des Celtes 28, Etterbeek" class="icon solid fa-map-marker-alt" target="_blank"><span class="label">Location</span></a></li>
							<li><a href="/#contact-us" class="icon solid fa-envelope"><span class="label">Email</span></a></li>
							<li><a href="https://www.instagram.com/black_box_concept/" class="icon brands fa-instagram" target="_blank"><span class="label">Instagram</span></a></li>
						</ul>
					</div>
					<div class="copyright">
						<ul>
							<li>&copy; Blackbox Concept. All rights reserved.  0032 472 67 37 67 | info@blackbox-concept.com </li>
							<li><Link to="/imprint">Imprint</Link></li>
							<li><Link to="/privacy-policy">Privacy Policy</Link></li>
						</ul>
				</div>
				</section>
			</div>
      </div>
    );
  }
}
