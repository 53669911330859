import React, { Component } from "react";

export default class Pilates extends Component {

	componentDidMount = () => window.scrollTo(0, 0);

	componentDidUpdate = prevProps => {
		if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
	};

	render() {
		return (
			<div class="service_bg">
				<div id="pilates">
					{/* <h2>PILATES</h2> */}
					<div class="row">
						<div class="col-6 col-12-medium">
							<div class="blockquote_div_container">
								<h2>INTENSITY</h2>
								<blockquote>
								Intensity, the key word is big sweat! 
								A class programmed to blow the steam out, burn a lot of calories, 
								sweat and train yourself in playful workouts.</blockquote>
							</div>
						</div>
						<div class="col-6 col-12-medium">
							<div>
								<div class="row gtr-50 gtr-uniform">
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}