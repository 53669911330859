import React, { Component } from "react";
import {Link} from 'react-router-dom';

export default class Footer extends Component {
	render() {
		return (
			<div>
				<section id="footer">
					<div class="inner">
						<ul class="icons">
							<li><a href="https://maps.google.com/?q=Avenue des Celtes 28, Etterbeek" class="icon solid fa-map-marker-alt" target="_blank"><span class="label">Location</span></a></li>
							<li><a href="#contact-us" class="icon solid fa-envelope"><span class="label">Email</span></a></li>
							<li><a href="https://www.instagram.com/black_box_concept/" class="icon brands fa-instagram" target="_blank"><span class="label">Instagram</span></a></li>
						</ul>
					</div>
					<div class="copyright">
						<ul>
							<li>&copy; Blackbox Concept. All rights reserved.  0032 472 67 37 67 | info@blackbox-concept.com </li>
							<li><Link to="/imprint">Imprint</Link></li>
							<li><Link to="/privacy-policy">Privacy Policy</Link></li>
						</ul>
				</div>
				</section>
			</div>
		)
	}
}