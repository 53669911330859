import React, { Component } from "react";

export default class Mobility extends Component {

	componentDidMount = () => window.scrollTo(0, 0);

	componentDidUpdate = prevProps => {
		if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
	};

	render() {
		return (
			<div class="service_bg">
				<div id="mobility">
					{/* <h2>MOBILITY</h2> */}
					<div class="row">
						<div class="col-6 col-12-medium">
							<div>
								<div class="row gtr-50 gtr-uniform">
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogapose1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo1.jpg" alt="" /></span></div>
									<div class="col-4"><span class="image fit"><img src="/images/temp/yogalogo2.jpg" alt="" /></span></div>
								</div>
							</div>
						</div>
						<div class="col-6 col-12-medium">
							<div class="blockquote_div_container">
								<h2>MOBILITY</h2>
								<blockquote>
									In our classes you will work in improving mobility in all or a single joint.
									In doing so you reduce the potential of imbalances, thus reducing the risk for injuries.
									Mobility training allows for full benefits of exercise by moving joints through their respective full range of motion,
									and helps you move better whether that is for daily activities or for sport. Join our classes and let us know if you have a
						specific joint that you wish to target.</blockquote>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}