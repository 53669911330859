import React from 'react';
import './App.css';
import Banner from './Components/Banner';
import Team from './Components/Team';
import Contact from './Components/Contact';
import Services from './Components/Services';
import AboutUs from './Components/AboutUs';
import PrivacyPolicy from './Components/Legal/PrivacyPolicy';
import Imprint from './Components/Legal/Imprint';
import FunctionalTraining from './Components/Classes_Offered/FunctionalTraining';
import Yoga from './Components/Classes_Offered/Yoga';
import Kettlebell from './Components/Classes_Offered/Kettlebell';
import PersonalTraining from './Components/Classes_Offered/PersonalTraining';
import Pilates from './Components/Classes_Offered/Pilates';
import Mobility from './Components/Classes_Offered/Mobility';
import Service from './Components/Classes_Offered/Service';
import Partners from './Components/Partners';
import Schedule from './Components/Schedule';
import BookYourSport from './Components/BookYourSport';

import {
	Switch,
	Link,
	Route
} from 'react-router-dom';

function App() {
  return (
    <div>
     {/* <!-- Header --> */}
			<header id="header">
				<a class="logo" href="/">Black box Concept</a>
				<nav>
					<a href="#menu">Menu</a>
				</nav>
			</header>

		{/* <!-- Nav --> */}
			<nav id="menu">
				<ul class="links">
					<li><Link to="/">Home</Link></li>
					<li><Link to="/#about-us">About</Link></li>
					<li><Link to="/#our-services">Services</Link></li>
					<li><Link to="/#pricing-membership">Pricing and Membership</Link></li>
					<li><Link to="/#our-team">Team</Link></li>
					<li><Link to="/#contact-us">Contact</Link></li>
					<li><Link to="/#book-your-sport">Book your spot</Link></li>
				</ul>
			</nav>

			<Switch>
				<Route exact path="/" component={Banner} />
				<Route path="/imprint" component={Imprint} />
				<Route path="/privacy-policy" component={PrivacyPolicy} />
				<Route path="/#about-us" component={AboutUs} />
				<Route path="/#our-services" component={Services} />
				<Route path="/#our-team" component={Team} />
				<Route path="/#contact-us" component={Contact} />
				<Route path="/service" component={Service} />
				<Route path="/service/#functional-training" component={FunctionalTraining} />
				<Route path="/service/#yoga" component={Yoga} />
				<Route path="/service/#kettlebell" component={Kettlebell} />
				<Route path="/service/#mobility" component={Mobility} />
				<Route path="/service/#personal-training" component={PersonalTraining} />
				<Route path="/service/#pilates" component={Pilates} />
				<Route path="/partners" component={Partners} />
				<Route path="/#pricing-membership" component={Schedule} />
				<Route path="/#book-your-sport" component={BookYourSport} />
			</Switch>
    </div>
  );
}

export default App;
