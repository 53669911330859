import React, { Component } from "react";
import Footer from './Footer';
import Team from './Team';
import Contact from './Contact';
import Services from './Services';
import AboutUs from './AboutUs';
import Schedule from './Schedule';
import BookYourSport from './BookYourSport';


export default class Banner extends Component {
	render() {
		return (
			<div>
				<div id="banner">
					<div class="inner">
						<span class="logo-image"><img src="/images/longwhite.png"></img></span>
						{/* <h1>Welcome to our gym</h1>
						<p>Don’t use machines, become one.</p>
						<ul class="actions stacked">
							<li><a href="#our-services" class="button large primary">Let’s get it</a></li>
							<li><a href="/#contact-us" class="button large">Contact us</a></li>
						</ul> */}
					</div>
				</div>
				<AboutUs />
				<Services />
				<Schedule />
				<Team />
				<BookYourSport />
				<Contact />
				<Footer />
			</div>
		)
	}
}